import L from 'leaflet'
import Color from 'color'
export default {
  data() {
    return {
      map: null,
      image: {
        width: 9723,
        height: 13750
      },
      config: {
        fullscreenControl: true,
        minZoom: 0,
        maxZoom: 6,
        crs: L.CRS.Simple,
        tap: false
      },
      markers: [],
      groups: {}
    }
  },
  methods: {
    initialize() {
      delete L.Icon.Default.prototype._getIconUrl
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
      })
      let map = L.map('map', this.config)
      let southWest = map.unproject([0, this.image.height], map.getMaxZoom())
      let northEast = map.unproject([this.image.width, 0], map.getMaxZoom())
      let centerPoint = map.unproject(
        [this.image.width / 2, this.image.height / 2],
        map.getZoom()
      )
      let maxBound = new L.LatLngBounds(southWest, northEast)

      L.imageOverlay('/map/map.svg', maxBound).addTo(map)
      map.setMaxBounds(maxBound)
      map.fitBounds(maxBound)
      map.setView(centerPoint, 2)
      map.setZoom(2)
      map.attributionControl
        .removeAttribution()
        .addAttribution('阳光丽景业委会')
      let blueprintLayer = L.imageOverlay('/map/map.jpg', maxBound)
      L.control
        .layers(
          {},
          {
            建筑蓝图: blueprintLayer
          }
        )
        .addTo(map)
      this.map = map
    },
    drawTrees() {
      // eslint-disable-next-line no-console
      console.log('[Map::drawTrees] 共有树木种类', this.genuses.length)
      this.markers = []
      let data = this.trees
      let groups = {}
      for (let genus of this.genuses) {
        let group = L.featureGroup()
        let items = data.filter(tree => tree.genus === genus._id)
        // eslint-disable-next-line no-console
        // console.log('[Map] 找到树木种类', genus.name, items.length)
        for (let item of items) {
          let fillColor = (genus.color ?? '#f03').trim()

          // eslint-disable-next-line no-console
          // console.log([item.longitude, item.latitude], fillColor)
          let color = fillColor //Color(fillColor).darken(0.5)
          try {
            color = Color(fillColor).darken(0.5)
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, fillColor)
          }
          let radius = isNaN(item.diameter) ? '0.1' : item.diameter / 100
          // let ref = this.$refs[`tree-${item._id}`]?.[0]
          // let content = ref.$el.innerHTML
          // eslint-disable-next-line no-console
          // console.log('[Map::drawTrees] ref', ref.$el.innerHTML)
          let marker = L.circle([item.latitude, item.longitude], {
            color,
            fillColor,
            fillOpacity: 0.5,
            radius,
            properties: { tree: item, genus }
          })
          marker
            // .bindPopup(content)
            // .on('mouseover', function () {
            //   this.openPopup()
            // })
            // .on('mouseout', function () {
            //   this.closePopup()
            // })
            .on('click', e => {
              let { options } = e.target
              let { tree, genus } = options.properties
              this.$refs.treeViewer.show(tree, genus)
            })
            .addTo(group)
          this.markers.push(marker)
        }
        if (items.length > 0) {
          // eslint-disable-next-line no-console
          // console.log('drawTrees', genus)
          this.map.addLayer(group)
          groups[genus._id] = group
        }
      }
      this.groups = groups
    },
    async updateTreeLists() {
      this.loading = true
      await this.fetch()
      for (let group of Object.values(this.groups)) {
        this.map.removeLayer(group)
      }

      this.drawTrees()
      this.loading = false
    }
  }
}
